import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { defer, redirect } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

const listComments = async (businessModelId: string) => {
  // Comment
  const commentQuery = client.models.Comment.listCommentByBusinessModelIdAndCreatedAt;
  const commentParams = {
    businessModelId,
    sortDirection: 'DESC',
  };
  const commentsRes = await fetchAllData<
    Schema['Comment']['type'],
    Schema['Comment']['secondaryIndexes']['listCommentByBusinessModelIdAndCreatedAt']['input']
  >(commentQuery, commentParams);

  const res = await Promise.all(
    commentsRes.data.map(async (comment) => {
      const user = await client.queries.GetUserResolver({ id: comment.userId });
      return {
        ...comment,
        commentedUserName: `${user?.data?.familyName} ${user?.data?.givenName}` ?? '',
        commentedUserPicture: user?.data?.iconPath ?? '',
      };
    })
  );

  return {
    comments: res,
  };
};

const listHypothesis = async (businessModelId: string) => {
  const hypothesisQuery = client.models.Hypothesis.listHypothesisByBusinessModelIdAndCreatedAt;
  const hypothesisParams = {
    businessModelId,
    sortDirection: 'DESC',
  };
  const res = await fetchAllData<
    Schema['Hypothesis']['type'],
    Schema['Hypothesis']['secondaryIndexes']['listHypothesisByBusinessModelIdAndCreatedAt']['input']
  >(hypothesisQuery, hypothesisParams);

  return {
    hypothesis: res.data,
  };
};

const listBusinessModelHistories = async (businessModelId: string) => {
  const businessModelHistoriesQuery =
    client.models.BusinessModelHistory.listBusinessModelHistoryByBusinessModelIdAndVersion;
  const businessModelHistoriesParams = {
    businessModelId,
    sortDirection: 'DESC',
    limit: 1,
  };
  const _businessModelHistoriesData = await fetchAllData<
    Schema['BusinessModelHistory']['type'],
    Schema['BusinessModelHistory']['secondaryIndexes']['listBusinessModelHistoryByBusinessModelIdAndVersion']['input']
  >(businessModelHistoriesQuery, businessModelHistoriesParams);
  const res = await Promise.all(
    _businessModelHistoriesData.data.map(async (historyData) => {
      const userQuery = await client.queries.GetUserResolver({ id: historyData.owner });

      return {
        ...historyData,
        fullName: `${userQuery?.data?.familyName} ${userQuery?.data?.givenName}` ?? '',
        iconPath: userQuery?.data?.iconPath ?? '',
      };
    })
  );

  return {
    businessModelHistories: res,
  };
};

const listBusinessModelFields = async (businessModelHistoryId: string) => {
  // BusinessModelField
  const businessModelFieldsQuery =
    client.models.BusinessModelField.listBusinessModelFieldByBusinessModelHistoryIdAndField;
  const businessModelFieldsParams = {
    businessModelHistoryId,
  };
  const res = await fetchAllData<
    Schema['BusinessModelField']['type'],
    Schema['BusinessModelField']['secondaryIndexes']['listBusinessModelFieldByBusinessModelHistoryIdAndField']['input']
  >(businessModelFieldsQuery, businessModelFieldsParams);

  return {
    businessModelFields: res.data,
  };
};

export const businessModelCanvasLoader = async (
  businessModelId?: string,
  businessModelHistoryId?: string
) => {
  if (!businessModelId || !businessModelHistoryId) {
    return redirect('/404');
  }

  const promiseBusinessModelDetail = Promise.all([
    listBusinessModelFields(businessModelHistoryId),
    listBusinessModelHistories(businessModelId),
    listHypothesis(businessModelId),
    listComments(businessModelId),
  ]);

  return defer({
    promiseBusinessModelDetail,
  });
};
