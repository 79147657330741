import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json, type Params } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { deleteBusinessModel } from 'src/actions/business-model';

import { getAuth } from 'src/auth/context';

import type { GenerateBusinessModelInput } from '../../type';

export const businessModelListAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'POST': {
      const data = Object.fromEntries(await request.formData()) as GenerateBusinessModelInput;

      const res = await generateBusinessModel(data);

      return json(res);
    }
    case 'DELETE': {
      const data = Object.fromEntries(await request.formData()) as {
        id: Schema['BusinessModel']['type']['id'];
      };

      const res = await deleteBusinessModel(data.id);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function generateBusinessModel(
  data: GenerateBusinessModelInput
): Promise<ActionResponse<null>> {
  try {
    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }
    const projectRes = (await client.models.Project.create({
      name: 'Business Model Canvas', // TODO: 仮置きなので修正する
      workspaceId: data.workspaceId,
      createdAt: new Date().toISOString(),
    })) as any;

    if (!projectRes) {
      throw new Error('Failed to create project');
    }

    const restOperation = await axios
      .post(
        `canvas`,
        {
          user_query: data.businessIdea,
          project_id: projectRes.data?.id,
          workspace_id: data.workspaceId,
          llm_model: 'claude-3-5-sonnet-20240620',
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.idToken.toString()}`,
          },
        }
      )
      .then((res) => res.data);

    if (restOperation.statusCode !== 200) {
      throw new Error('Failed to mutate businessModelCanvasResolver');
    }

    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth() + 1;

    const thisMonthData = await client.models.WorkspaceAnalytics.get({
      workspaceId: data.workspaceId,
      year: thisYear,
      month: thisMonth,
    });
    // もし今月初めてのビジネスモデルキャンバス作成なら初期データを作成する
    if (!thisMonthData || !thisMonthData.data) {
      await client.models.WorkspaceAnalytics.create({
        id: '',
        workspaceId: data.workspaceId,
        year: thisYear,
        month: thisMonth,
        count: 1,
        input_token:
          restOperation.body.canvas_token_usage.input_tokens +
          restOperation.body.title_token_usage.input_tokens,
        output_token:
          restOperation.body.canvas_token_usage.output_tokens +
          restOperation.body.title_token_usage.output_tokens,
      });
    }
    // すでに今月に入ってビジネスモデルを生成している場合は、カウンターをインクリメントする
    else {
      await client.models.WorkspaceAnalytics.update({
        workspaceId: data.workspaceId,
        year: thisYear,
        month: thisMonth,
        count: thisMonthData.data.count + 1,
        input_token:
          restOperation.body.canvas_token_usage.input_tokens +
          restOperation.body.title_token_usage.input_tokens +
          thisMonthData.data.input_token,
        output_token:
          restOperation.body.canvas_token_usage.output_tokens +
          restOperation.body.title_token_usage.output_tokens +
          thisMonthData.data.output_token,
      });
    }

    return {
      userMessage: {
        message: 'ビジネスモデルキャンバスを作成しました。',
        severity: 'success',
      },
      redirectPath: paths.workspace.edit(
        data.workspaceId,
        restOperation.body.bm_id || '',
        restOperation.body.bmh_id || ''
      ),
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message: 'ビジネスモデルキャンバスの作成に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}
