import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { defer, redirect } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

export const businessModelListLoader = async (workspaceId?: string) => {
  if (!workspaceId) {
    return redirect('/404');
  }

  const query = client.models.BusinessModel.listBusinessModelByWorkspaceIdAndCreatedAt;
  const params = {
    workspaceId,
    sortDirection: 'DESC',
  };

  const businessModels = await fetchAllData<
    Schema['BusinessModel']['type'],
    Schema['BusinessModel']['secondaryIndexes']['listBusinessModelByWorkspaceIdAndCreatedAt']['input']
  >(query, params);

  const promiseBusinessModelList = Promise.all(
    businessModels.data.map(async (businessModel) => {
      const businessModelHistoriesQuery =
        client.models.BusinessModelHistory.listBusinessModelHistoryByBusinessModelIdAndVersion;
      const businessModelHistoriesParams = {
        businessModelId: businessModel.id,
        sortDirection: 'DESC',
        limit: 1,
      };
      const businessModelHistories = await fetchAllData<
        Schema['BusinessModelHistory']['type'],
        Schema['BusinessModelHistory']['secondaryIndexes']['listBusinessModelHistoryByBusinessModelIdAndVersion']['input']
      >(businessModelHistoriesQuery, businessModelHistoriesParams);

      return businessModelHistories.data[0];
    })
  );

  return defer({
    promiseBusinessModelList,
  });
};
