import type { ActionResponse } from 'src/types/common';

import { json, type Params } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { deleteBusinessModel } from 'src/actions/business-model';

import type {
  DeleteBusinessModelInput,
  CopyBusinessModelCanvasInput,
  UpdateBusinessModelTitleInput,
  RevertBusinessModelCanvasInput,
  UpdateBusinessModelTitleOutput,
} from '../../type';

export const businessModelCanvasAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'PUT': {
      const data = Object.fromEntries(await request.formData()) as RevertBusinessModelCanvasInput;

      const res = await revertBusinessModelCanvas(data);

      return json(res);
    }
    case 'POST': {
      const data = Object.fromEntries(await request.formData()) as CopyBusinessModelCanvasInput;

      const res = await copyBusinessModelCanvas(data);

      return json(res);
    }
    case 'PATCH': {
      const data = Object.fromEntries(await request.formData()) as UpdateBusinessModelTitleInput;

      const res = await updateBusinessModelTitle(data);

      return json(res);
    }
    case 'DELETE': {
      const data = Object.fromEntries(await request.formData()) as DeleteBusinessModelInput;

      const res = await deleteBusinessModel(data.businessModelId);

      return json({
        ...res,
        redirectPath: paths.workspace.businessModel(data.workspaceId),
      });
    }
    default: {
      return null;
    }
  }
};

async function updateBusinessModelTitle(
  data: UpdateBusinessModelTitleInput
): Promise<ActionResponse<UpdateBusinessModelTitleOutput>> {
  try {
    const res = await client.models.BusinessModelHistory.update({
      id: data.businessModelHistoryId,
      title: data.title,
    });

    return {
      userMessage: {
        message: 'ビジネスモデルのタイトルを更新しました',
        severity: 'success',
      },
      data: res?.data,
    };
  } catch (err) {
    console.error(err);
    sentryCaptureError(err);

    return {
      userMessage: {
        message: 'ビジネスモデルのタイトルの更新に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}

async function revertBusinessModelCanvas(
  data: RevertBusinessModelCanvasInput
): Promise<ActionResponse<null>> {
  try {
    await client.mutations.RevertAndCopyBusinessModelCanvasResolver({
      businessModelHistoryId: data.businessModelHistoryId,
      type: 'revert',
    });

    return {
      userMessage: {
        message: 'ビジネスモデルキャンバスを復元しました。',
        severity: 'success',
      },
    };
  } catch (err) {
    console.error(err);
    sentryCaptureError(err);

    return {
      userMessage: {
        message: 'ビジネスモデルキャンバスの復元に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}

async function copyBusinessModelCanvas(
  data: CopyBusinessModelCanvasInput
): Promise<ActionResponse<null>> {
  try {
    await client.mutations.RevertAndCopyBusinessModelCanvasResolver({
      businessModelHistoryId: data.businessModelHistoryId,
      type: 'copy',
    });

    return {
      userMessage: {
        message: 'ビジネスモデルキャンバスを複製しました。',
        severity: 'success',
      },
      redirectPath: paths.workspace.businessModel(data.workspaceId),
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message: 'ビジネスモデルキャンバスの複製に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}
