import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { withAuthCheck } from 'src/utils/with-auth-check';

import { AuthSignInLayout } from 'src/layouts/auth-signin';
import { AuthSignUpLayout } from 'src/layouts/auth-signup';
import { AuthCenteredLayout } from 'src/layouts/auth-centered';
import { AuthInviteSignUpLayout } from 'src/layouts/invite-auth-signup';

import { SplashScreen } from 'src/components/loading-screen';

import { myProfileLoader } from 'src/sections/auth/loaders/profile/loader';
import { authUserProfileAction } from 'src/sections/auth/actions/profile/action';
import { myWorkspacesLoader } from 'src/sections/auth/loaders/my-workspaces/loader';

import { AuthGuard, GuestGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

/** **************************************
 * Amplify
 *************************************** */
const Amplify = {
  SignInPage: lazy(() => import('src/pages/auth/sign-in')),
  SignUpPage: lazy(() => import('src/pages/auth/sign-up')),
  InviteSignUp: lazy(() => import('src/pages/auth/invite-sign-up')),
  VerifyPage: lazy(() => import('src/pages/auth/verify')),
  VerifyInvitePage: lazy(() => import('src/pages/auth/verify-invite')),
  UpdatePasswordPage: lazy(() => import('src/pages/auth/update-password')),
  ResetPasswordPage: lazy(() => import('src/pages/auth/reset-password')),
  MyWorkspacePage: lazy(() => import('src/pages/auth/my-workspace')),
  ProfilePage: lazy(() => import('src/pages/auth/profile')),
};

const Page500 = lazy(() => import('src/pages/error/500'));

const authAmplify = {
  children: [
    {
      path: 'sign-in',
      element: (
        <GuestGuard>
          <AuthSignInLayout>
            <Amplify.SignInPage />
          </AuthSignInLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'sign-up',
      element: (
        <GuestGuard>
          <AuthSignUpLayout>
            <Amplify.SignUpPage />
          </AuthSignUpLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'invite-sign-up',
      element: (
        <GuestGuard>
          <AuthInviteSignUpLayout>
            <Amplify.InviteSignUp />
          </AuthInviteSignUpLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'profile',
      element: (
        <AuthCenteredLayout>
          <Amplify.ProfilePage />
        </AuthCenteredLayout>
      ),
      action: authUserProfileAction,
      loader: withAuthCheck(myProfileLoader),
    },
    {
      path: 'verify',
      element: (
        <AuthCenteredLayout>
          <Amplify.VerifyPage />
        </AuthCenteredLayout>
      ),
    },
    {
      path: 'verify/invite',
      element: (
        <GuestGuard>
          <AuthCenteredLayout>
            <Amplify.VerifyInvitePage />
          </AuthCenteredLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <AuthCenteredLayout>
          <Amplify.ResetPasswordPage />
        </AuthCenteredLayout>
      ),
    },
    {
      path: 'update-password',
      element: (
        <AuthCenteredLayout>
          <Amplify.UpdatePasswordPage />
        </AuthCenteredLayout>
      ),
    },
    {
      path: 'my-workspaces',
      element: (
        <AuthGuard>
          <AuthCenteredLayout>
            <Amplify.MyWorkspacePage />
          </AuthCenteredLayout>
        </AuthGuard>
      ),
      loader: withAuthCheck(myWorkspacesLoader),
    },
  ],
};

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    errorElement: <Page500 />,
    children: [authAmplify],
  },
];
