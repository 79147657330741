import type { UpdateUserInput } from 'src/types/user';
import type { ActionResponse } from 'src/types/common';

import { json, type Params } from 'react-router-dom';

import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { CONFIG } from 'src/config-global';
import { updateUserProfileAction } from 'src/actions/user';

export const authUserProfileAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'POST': {
      const data = Object.fromEntries(await request.formData()) as UpdateUserInput;

      const res = await setUserProfile(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function setUserProfile(data: UpdateUserInput): Promise<ActionResponse<null>> {
  try {
    await updateUserProfileAction(data);
    return {
      userMessage: {
        message: 'プロフィールを設定しました。',
        severity: 'success',
      },
      redirectPath: CONFIG.auth.redirectPath,
    };
  } catch (err) {
    sentryCaptureError(err);

    return {
      userMessage: {
        message: 'プロフィールの更新に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}
