import type { ActionResponse } from 'src/types/common';

import { json, type Params } from 'react-router-dom';
import { updatePassword as cognitoUpdatePassword } from 'aws-amplify/auth';

import type { UpdateUserPasswordInput } from '../../type';

export const userPasswordAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'PUT': {
      const data = Object.fromEntries(await request.formData()) as UpdateUserPasswordInput;
      const res = await updatePassword(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function updatePassword(input: UpdateUserPasswordInput): Promise<ActionResponse<null>> {
  try {
    await cognitoUpdatePassword({
      oldPassword: input.oldPassword,
      newPassword: input.newPassword,
    });

    return {
      userMessage: {
        message: 'パスワードを更新しました。',
        severity: 'success',
      },
    };
  } catch (err) {
    console.error(err);

    return {
      userMessage: {
        message: 'パスワードの更新に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}
