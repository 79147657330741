import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { Link, MenuItem, Skeleton, ButtonBase, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { Iconify } from 'src/components/iconify';
import { usePopover, CustomPopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

type Props = {
  data: { id: string; name: string; owner: string; role: Schema['UserRole']['type'] }[];
};

export default function WorkspaceSelector({ data }: Props) {
  const params = useParams();

  const currentWorkspaceId = params.workspaceId as string;

  const popover = usePopover();

  const currentWorkspace = data?.find((workspace) => workspace.id === currentWorkspaceId);

  return (
    <>
      <ButtonBase
        onClick={popover.onOpen}
        sx={{
          pl: 0.5,
          py: 0.5,
          pr: 0.5,
          borderRadius: 1,
          bgcolor: 'background.paper',
          maxWidth: 300,
        }}
      >
        <Iconify
          width={22}
          height={22}
          icon="solar:tag-horizontal-bold"
          color="text.secondary"
          sx={{ mr: 1 }}
        />

        <Typography variant="subtitle2" noWrap color="text.secondary">
          {currentWorkspace ? currentWorkspace.name : <Skeleton variant="text" width={100} />}
        </Typography>
        <Iconify
          width={16}
          icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          sx={{ ml: 0.5 }}
          color="text.secondary"
        />
      </ButtonBase>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        anchorEl={popover.anchorEl}
        sx={{ maxWidth: 300 }}
      >
        {data?.map((workspace, index) => (
          <Link
            key={index}
            component={RouterLink}
            href={paths.workspace.businessModel(workspace.id || '')}
            variant="body2"
            color="text.secondary"
            underline="none"
            sx={{ alignSelf: 'flex-end' }}
          >
            <MenuItem key={index} selected={workspace.id === currentWorkspaceId}>
              <Iconify
                width={22}
                height={22}
                icon="solar:tag-horizontal-bold"
                color="text.secondary"
              />
              <Typography variant="subtitle2" noWrap color="text.secondary">
                {workspace.name}
              </Typography>
            </MenuItem>
          </Link>
        ))}
      </CustomPopover>
    </>
  );
}
