interface ListResponse<T> {
  data: T[];
  nextToken?: string;
}

type Params<V> = V & { nextToken?: string | null };

export async function fetchAllData<T, V>(
  query: any,
  params: Params<V>,
  nextToken: string | null | undefined = null,
  accumulatedData: T[] = []
): Promise<{ data: T[] }> {
  if (nextToken) {
    params.nextToken = nextToken;
  }

  const response: ListResponse<T> = await query(params);

  const newData = response.data;
  accumulatedData = accumulatedData.concat(newData);

  if (response.nextToken) {
    return fetchAllData(query, params, response.nextToken, accumulatedData);
  }

  return {
    data: accumulatedData,
  };
}
