import { useState, useEffect } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { CONFIG } from 'src/config-global';

import { SplashScreen } from 'src/components/loading-screen';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function GuestGuard({ children }: Props) {
  const router = useRouter();

  const searchParams = useSearchParams();

  const { user, loading, authenticated } = useAuthContext();

  const [isChecking, setIsChecking] = useState<boolean>(true);

  const returnTo = searchParams.get('returnTo') || CONFIG.auth.redirectPath;

  let path: string | undefined;

  const notProfile =
    !user?.given_name ||
    !user?.family_name ||
    !user?.company ||
    !user?.position ||
    !user?.department ||
    !user?.phoneNumber;

  // プロフィールが未設定の場合はプロフィール画面へ遷移
  if (notProfile) {
    path = paths.auth.profile;
    // ワークスペースが1つの場合はそのワークスペースへ遷移
  } else if (user?.workspaces.length === 1) {
    path = paths.workspace.businessModel(user?.workspaces[0].id);
  } else {
    path = returnTo;
  }

  const checkPermissions = async (): Promise<void> => {
    if (loading) {
      return;
    }

    if (authenticated && path) {
      router.replace(path);
      return;
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, loading]);

  if (isChecking) {
    return <SplashScreen />;
  }

  return <>{children}</>;
}
