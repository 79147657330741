import { redirect } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { client } from 'src/utils/amplify-client-utils';

import { getAuth } from 'src/auth/context';

export const myWorkspacesLoader = async () => {
  const auth = await getAuth();

  if (!auth || !auth.sub) {
    return redirect(paths.auth.signIn);
  }

  const workspaceUsers = await client.models.WorkspaceUser.listWorkspaceUserByUserId({
    userId: auth.sub,
  });

  const workspaces = await Promise.all(
    (workspaceUsers.data ?? []).map(async (workspaceUser) => {
      const workspace = await client.models.Workspace.get({ id: workspaceUser.workspaceId });

      return {
        id: workspaceUser.workspaceId,
        role: workspaceUser.role,
        name: workspace.data?.name,
        owner: workspace.data?.owner,
      };
    })
  );

  return workspaces;
};
