import type { UpdateUserInput } from 'src/types/user';

import { updateUserAttributes } from 'aws-amplify/auth';

import { client } from 'src/utils/amplify-client-utils';

export const updateUserProfileAction = async (input: UpdateUserInput) => {
  const userAttributes: {
    [key: string]: string;
  } = {
    given_name: input.givenName,
    family_name: input.familyName,
  };

  if (input?.iconPath) {
    userAttributes.picture = input.iconPath;
  }

  const res = await Promise.all([
    updateUserAttributes({
      userAttributes,
    }),
    client.models.User.update({
      id: input.userId,
      iconPath: input?.iconPath,
      givenName: input.givenName,
      familyName: input.familyName,
      phoneNumber: input.phoneNumber,
      department: input.department,
      company: input.company,
      position: input.position,
    }),
  ]);

  return res[1];
};
