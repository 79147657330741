import { Suspense } from 'react';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import App from './app';
import outputs from '../amplify_outputs.json';

Amplify.configure(outputs);

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <Suspense>
      <App />
    </Suspense>
  </HelmetProvider>
);
