import type { ActionResponse } from 'src/types/common';

import { json, type Params } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { getAuth } from 'src/auth/context';

import type { UpdateBusinessModelFieldInput } from '../../type';

export const businessModelCanvasFieldAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'PUT': {
      const data = Object.fromEntries(await request.formData()) as UpdateBusinessModelFieldInput;

      const res = await updateBusinessModelField(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function updateBusinessModelField(
  data: UpdateBusinessModelFieldInput
): Promise<ActionResponse<null>> {
  try {
    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    const restOperation = await axios
      .put(
        `canvas/edit`,
        {
          workspace_id: data.workspaceId,
          bm_id: data.businessModelId,
          bmh_id: data.businessModelHistoryId,
          edit_field_name: data.editFieldName,
          edit_value: data.editValue,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.idToken.toString()}`,
          },
        }
      )
      .then((res) => res.data);

    if (restOperation.statusCode !== 200 || !restOperation.body.bmh_id) {
      throw new Error('Failed to edit business model');
    }

    return {
      userMessage: {
        message: 'ビジネスモデルフィールドを更新しました。',
        severity: 'success',
      },
      redirectPath: paths.workspace.edit(
        data.workspaceId,
        data.businessModelId,
        restOperation.body.bmh_id
      ),
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message: 'ビジネスモデルフィールドの更新に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}
