import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

// ----------------------------------------------------------------------

export function Section() {
  return (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        bgcolor: 'rgb(51,51,51)',
      }}
    >
      <Box
        component="img"
        alt="auth"
        src="/assets/illustrations/login.svg"
        sx={{
          width: '100%',
        }}
      />
    </Stack>
  );
}
