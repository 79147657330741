// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    signIn: `${ROOTS.AUTH}/sign-in`,
    signUp: `${ROOTS.AUTH}/sign-up`,
    inviteSignUp: `${ROOTS.AUTH}/invite-sign-up`,
    verify: `${ROOTS.AUTH}/verify`,
    myWorkspaces: `${ROOTS.AUTH}/my-workspaces`,
    profile: `${ROOTS.AUTH}/profile`,
    updatePassword: `${ROOTS.AUTH}/update-password`,
    resetPassword: `${ROOTS.AUTH}/reset-password`,
  },
  // MY WORKSPACE
  workspace: {
    root: (id: string) => `/${id}/business-models`,
    businessModel: (id: string) => `/${id}/business-models`,
    edit: (workspaceId: string, businessModelId: string, businessModelHistoryId: string) =>
      `/${workspaceId}/business-models/${businessModelId}/${businessModelHistoryId}`,
    member: {
      root: (id: string) => `/${id}/members`,
      list: (id: string) => `/${id}/members`,
    },
    user: {
      root: (id: string) => `/${id}/user`,
    },
  },
};
