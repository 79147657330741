import { redirect } from 'react-router-dom';
import { getUrl } from 'aws-amplify/storage';

import { paths } from 'src/routes/paths';

import { getAuth } from 'src/auth/context';

export const userProfileLoader = async () => {
  const auth = await getAuth();

  if (!auth) {
    return redirect(paths.auth.signIn);
  }

  let iconUrl = '/assets/icons/avatar/avatar.png';

  if (auth.picture) {
    const res = await getUrl({
      path: auth?.picture,
    });

    iconUrl = res.url.href;
  }

  if (!auth.sub || !auth.given_name || !auth.family_name) {
    return redirect(paths.auth.signIn);
  }

  const user = {
    id: auth.sub,
    givenName: auth.given_name,
    familyName: auth.family_name,
    iconUrl,
  };

  return user;
};
