import type { UpdateUserInput } from 'src/types/user';
import type { ActionResponse } from 'src/types/common';

import { json, type Params } from 'react-router-dom';

import { updateUserProfileAction } from 'src/actions/user';

export const userProfileAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'PUT': {
      const input = Object.fromEntries(await request.formData()) as UpdateUserInput;
      const res = await updateUserProfile(input);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function updateUserProfile(data: UpdateUserInput): Promise<ActionResponse<null>> {
  try {
    await updateUserProfileAction(data);

    return {
      userMessage: {
        message: 'プロフィールを更新しました。',
        severity: 'success',
      },
    };
  } catch (err) {
    console.error(err);

    return {
      userMessage: {
        message: 'プロフィールの更新に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}
