import type { BoxProps } from '@mui/material/Box';

import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';

import { RouterLink } from 'src/routes/components';

import { logoClasses } from './classes';

// ----------------------------------------------------------------------

export type LogoProps = BoxProps & {
  href?: string;
  disableLink?: boolean;
};

export const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ width = 40, height = 40, disableLink = false, className, href = '/', sx, ...other }, ref) => {
    /*
     * OR using local (public folder)
     * const logo = ( <Box alt="logo" component="img" src={`${CONFIG.site.basePath}/logo/logo-single.svg`} width={width} height={height} /> );
     */

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          ...sx,
        }}
        {...other}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M13.2028 0.546875H0.706669C0.704712 0.546875 0.703125 0.54846 0.703125 0.550415V29.5277C0.703125 29.5297 0.704712 29.5312 0.706669 29.5312H13.2028C13.2048 29.5312 13.2063 29.5297 13.2063 29.5277V0.550415C13.2063 0.54846 13.2048 0.546875 13.2028 0.546875Z"
            fill="#5900FF"
          />
          <path
            d="M26.1239 15.0373C28.0271 13.5539 29.2497 11.2456 29.2497 8.64706C29.2533 4.17567 25.6207 0.546875 21.1411 0.546875C20.9674 0.546875 20.7938 0.553956 20.6201 0.564576C20.5209 0.561036 20.4216 0.546875 20.3224 0.546875H14.6201V29.5312H20.3224C20.4216 29.5312 20.5209 29.5206 20.6201 29.5135C20.7902 29.5242 20.9639 29.5312 21.1411 29.5312C25.6207 29.5312 29.2533 25.9024 29.2533 21.4275C29.2533 18.8289 28.0271 16.5207 26.1275 15.0373H26.1239Z"
            fill="#5900FF"
          />
        </svg>
      </Box>
    );

    return (
      <NoSsr
        fallback={
          <Box
            width={width}
            height={height}
            className={logoClasses.root.concat(className ? ` ${className}` : '')}
            sx={{ flexShrink: 0, display: 'inline-flex', verticalAlign: 'middle', ...sx }}
          />
        }
      >
        <Box
          ref={ref}
          component={RouterLink}
          href={href}
          className={logoClasses.root.concat(className ? ` ${className}` : '')}
          aria-label="logo"
          sx={{
            flexShrink: 0,
            display: 'inline-flex',
            verticalAlign: 'middle',
            ...(disableLink && { pointerEvents: 'none' }),
            ...sx,
          }}
          {...other}
        >
          {logo}
        </Box>
      </NoSsr>
    );
  }
);
