import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { defer, redirect } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';

async function listMembersByWorkspaceId(input: {
  workspaceId: Schema['ListCognitoUsersByWorkspaceIdResolver']['args']['workspaceId'];
}) {
  const res = await client.queries.ListCognitoUsersByWorkspaceIdResolver(input);

  if (res.errors) {
    throw new Error('Failed to list users');
  }

  const users = res.data?.map((user) => ({
    familyName: user?.familyName,
    givenName: user?.givenName,
    email: user?.email,
    picture: user?.picture,
    id: user?.id,
  }));

  const memberRes = await Promise.all(
    (users ?? []).map(async (user) => {
      const role = await getUserRole({ workspaceId: input.workspaceId, userId: user.id ?? '' });

      return {
        ...user,
        role,
      };
    })
  );

  return {
    members: memberRes,
  };
}

async function getWorkspaceOwner(input: { id: Schema['Workspace']['type']['id'] }) {
  const { data } = await client.models.Workspace.get(input);
  return {
    workspaceOwner: data?.owner,
  };
}

async function getUserRole(input: {
  workspaceId: Schema['WorkspaceUser']['type']['workspaceId'];
  userId: Schema['WorkspaceUser']['type']['userId'];
}) {
  const { data } = await client.models.WorkspaceUser.get(input);
  return data?.role;
}

export const memberListLoader = async (workspaceId?: string) => {
  if (!workspaceId) {
    return redirect('/404');
  }

  const getWorkspaceOwnerRes = getWorkspaceOwner({ id: workspaceId });

  const listMembersRes = listMembersByWorkspaceId({ workspaceId });

  const promiseMembers = Promise.all([listMembersRes, getWorkspaceOwnerRes]);

  return defer({ promiseMembers });
};
