import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json, type Params } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { getAuth } from 'src/auth/context';

import type { DeleteMemberOutput, InviteMemberOutput } from '../../type';

export const memberListAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'POST': {
      const data = Object.fromEntries(
        await request.formData()
      ) as Schema['InviteUserResolver']['args'];

      const res = await inviteUser(data);

      return json(res);
    }
    case 'DELETE': {
      const data = Object.fromEntries(
        await request.formData()
      ) as Schema['DeleteMemberFromWorkspaceResolver']['args'];

      const res = await deleteUser(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

const inviteUser = async (
  data: Schema['InviteUserResolver']['args']
): Promise<ActionResponse<InviteMemberOutput>> => {
  try {
    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        redirectPath: paths.auth.signIn,
      };
    }

    const res = await client.mutations.InviteUserResolver(data);

    // 復旧可能なエラーの場合は、エラーメッセージを表示する
    if (res.data?.message) {
      return {
        userMessage: {
          message: res.data.message,
          severity: 'info',
        },
      };
    }

    if (res.errors) {
      throw new Error(res.errors[0].message);
    }

    if (!res.data?.success) {
      throw new Error('failed to invite user');
    }

    return {
      userMessage: {
        severity: 'success',
        message: 'メンバーを招待しました。',
      },
      data: {
        ok: res.data?.success,
      },
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        severity: 'error',
        message: 'メンバーの招待に失敗しました。時間を置いて再度お試しください。',
      },
    };
  }
};

const deleteUser = async (
  data: Schema['DeleteMemberFromWorkspaceResolver']['args']
): Promise<ActionResponse<DeleteMemberOutput>> => {
  try {
    const res = await client.mutations.DeleteMemberFromWorkspaceResolver(data);

    if (res.data?.message) {
      return {
        userMessage: {
          message: res.data.message,
          severity: 'info',
        },
      };
    }

    if (res.errors) {
      throw new Error(res.errors[0].message);
    }

    return {
      userMessage: {
        severity: 'success',
        message: 'メンバーをワークスペースから削除しました。',
      },
      data: {
        userId: data.userId,
      },
    };
  } catch (err) {
    console.error(err);

    return {
      userMessage: {
        severity: 'error',
        message: 'メンバーの削除に失敗しました。時間を置いて再度お試しください。',
      },
    };
  }
};
