import type { AvatarProps } from '@mui/material';

import { useState, useEffect } from 'react';
import { getUrl } from 'aws-amplify/storage';

import { Avatar } from '@mui/material';

// ----------------------------------------------------------------------

interface S3AvatarProps extends AvatarProps {
  iconPath?: string;
  displayName?: string;
}

export default function S3Avatar({ iconPath, displayName, sx }: S3AvatarProps) {
  const [iconUrl, setIconUrl] = useState<string>('/assets/icons/avatar/avatar.png');

  const handleGetUrl = async () => {
    try {
      const url = await getUrl({
        path: iconPath || '',
      });
      setIconUrl(url.url.href);
    } catch (error) {
      console.error('Failed to get URL:', error);
    }
  };

  useEffect(() => {
    if (iconPath) {
      handleGetUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconPath]);
  return (
    <Avatar
      src={iconUrl}
      alt={displayName || ''}
      sx={{
        width: 36,
        height: 36,
        border: (theme) => `solid 2px ${theme.palette.background.default}`,
        ...sx,
      }}
    />
  );
}
