import type {
  SignUpInput,
  SignInInput,
  ConfirmSignUpInput,
  ResetPasswordInput,
  ResendSignUpCodeInput,
  ConfirmResetPasswordInput,
} from 'aws-amplify/auth';

import {
  fetchAuthSession,
  signIn as _signIn,
  signUp as _signUp,
  signOut as _signOut,
  fetchUserAttributes,
  confirmSignUp as _confirmSignUp,
  resetPassword as _resetPassword,
  confirmSignIn as _confirmSignIn,
  resendSignUpCode as _resendSignUpCode,
  confirmResetPassword as _confirmResetPassword,
} from 'aws-amplify/auth';

// ----------------------------------------------------------------------

export type SignInParams = SignInInput;

export type SignUpParams = SignUpInput & { firstName: string; lastName: string };

export type ResendSignUpCodeParams = ResendSignUpCodeInput;

export type ConfirmSignUpParams = ConfirmSignUpInput;

export type ResetPasswordParams = ResetPasswordInput;

export type ConfirmResetPasswordParams = ConfirmResetPasswordInput;

export type UpdateInviteUserPasswordParams = {
  username: string;
  tempPass: string;
  newPass: string;
};

/** **************************************
 * Sign in
 *************************************** */
export const signInWithPassword = async ({ username, password }: SignInParams): Promise<void> => {
  await _signIn({ username, password });
};

/** **************************************
 * Sign up
 *************************************** */
export const signUp = async ({
  username,
  password,
  firstName,
  lastName,
}: SignUpParams): Promise<void> => {
  await _signUp({
    username,
    password,
    options: { userAttributes: { email: username, given_name: firstName, family_name: lastName } },
  });
};

/** **************************************
 * Confirm sign up
 *************************************** */
export const confirmSignUp = async ({
  username,
  confirmationCode,
}: ConfirmSignUpParams): Promise<void> => {
  await _confirmSignUp({ username, confirmationCode });
};

/** **************************************
 * Resend code sign up
 *************************************** */
export const resendSignUpCode = async ({ username }: ResendSignUpCodeParams): Promise<void> => {
  await _resendSignUpCode({ username });
};

/** **************************************
 * Sign out
 *************************************** */
export const signOut = async (): Promise<void> => {
  await _signOut();
};

/** **************************************
 * Reset password
 *************************************** */
export const resetPassword = async ({ username }: ResetPasswordParams): Promise<void> => {
  await _resetPassword({ username });
};

/** **************************************
 * Update password
 *************************************** */
export const updatePassword = async ({
  username,
  confirmationCode,
  newPassword,
}: ConfirmResetPasswordParams): Promise<void> => {
  await _confirmResetPassword({ username, confirmationCode, newPassword });
};

export const getTokens = async () => {
  const authSession = await fetchAuthSession();

  if (authSession) {
    const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};

    if (idToken && accessToken) {
      return {
        accessToken,
        idToken,
      };
    }
  }
  return null;
};

export const getAuth = async () => {
  const tokens = await getTokens();

  const userAttributes = await fetchUserAttributes();

  return { ...userAttributes, ...tokens };
};

export const updateInviteUserPassword = async ({
  username,
  tempPass,
  newPass,
}: UpdateInviteUserPasswordParams) => {
  const user = await _signIn({
    username,
    password: tempPass,
  });

  if (user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
    await _confirmSignIn({
      challengeResponse: newPass,
    });
  }
};
