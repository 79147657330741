import type { RouteObject } from 'react-router-dom';

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { withAuthCheck } from 'src/utils/with-auth-check';

import { WorkspaceLayout } from 'src/layouts/workspace';

import { LoadingScreen } from 'src/components/loading-screen';

import { userPasswordAction } from 'src/sections/workspace/user/actions/password/action';
import { userProfileAction } from 'src/sections/workspace/user/actions/user-profile/action';
import { userProfileLoader } from 'src/sections/workspace/user/loaders/user-profile/loader';
import { memberListAction } from 'src/sections/workspace/members/actions/member-list/action';
import { memberListLoader } from 'src/sections/workspace/members/loaders/member-list/loader';
import { businessModelListAction } from 'src/sections/workspace/business-models/actions/business-model-list/action';
import { businessModelListLoader } from 'src/sections/workspace/business-models/loaders/business-model-list/loader';
import { businessModelCanvasAction } from 'src/sections/workspace/business-models/business-model/actions/canvas/action';
import { businessModelHypothesisAction } from 'src/sections/workspace/business-models/business-model/actions/hypothesis/action';
import { businessModelCanvasFieldAction } from 'src/sections/workspace/business-models/business-model/actions/canvas-field/action';
import { businessModelCanvasLoader } from 'src/sections/workspace/business-models/business-model/loaders/business-model-canvas/loader';

import { AuthGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

// Index
const IndexPage = lazy(() => import('src/pages/workspace'));

// Invited
const WorkspaceInvited = lazy(() => import('src/pages/workspace/invited'));

// Business Models
const BusinessModelListPage = lazy(() => import('src/pages/workspace/business-models'));
const BusinessModelDetailPage = lazy(
  () => import('src/pages/workspace/business-models/business-model/index')
);

// Member
const MemberListPage = lazy(() => import('src/pages/workspace/members'));

// User
const UserProfilePage = lazy(() => import('src/pages/workspace/user'));

const Page500 = lazy(() => import('src/pages/error/500'));

// ----------------------------------------------------------------------

const layoutContent = (
  <WorkspaceLayout>
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  </WorkspaceLayout>
);

export const dashboardRoutes: RouteObject[] = [
  {
    path: ':workspaceId',
    element: <AuthGuard>{layoutContent}</AuthGuard>,
    errorElement: <Page500 />,
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'invited',
        element: <WorkspaceInvited />,
      },
      {
        path: 'business-models',
        children: [
          {
            index: true,
            element: <BusinessModelListPage />,
            loader: withAuthCheck(({ params }) => businessModelListLoader(params.workspaceId)),
            action: businessModelListAction,
          },
          {
            path: ':businessModelId/:businessModelHistoryId',
            element: <BusinessModelDetailPage />,
            loader: withAuthCheck(({ params }) =>
              businessModelCanvasLoader(params.businessModelId, params.businessModelHistoryId)
            ),
            children: [
              {
                path: 'canvas',
                action: businessModelCanvasAction,
              },
              {
                path: 'canvas-field',
                action: businessModelCanvasFieldAction,
              },
              {
                path: 'hypothesis',
                action: businessModelHypothesisAction,
              },
            ],
          },
        ],
      },
      {
        path: 'members',
        element: <MemberListPage />,
        loader: withAuthCheck(({ params }) => memberListLoader(params.workspaceId)),
        action: memberListAction,
      },
      {
        path: 'user',
        loader: withAuthCheck(userProfileLoader),
        element: <UserProfilePage />,
        children: [
          {
            path: 'profile',
            action: userProfileAction,
          },
          {
            path: 'password',
            action: userPasswordAction,
          },
        ],
      },
    ],
  },
];
