import { redirect } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { getAuth } from 'src/auth/context';

export const myProfileLoader = async () => {
  const auth = await getAuth();

  if (!auth || !auth.sub) {
    return redirect(paths.auth.signIn);
  }

  return {
    givenName: auth?.given_name,
    familyName: auth?.family_name,
  };
};
