import type { ActionResponse } from 'src/types/common';

import { json, type Params } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { getAuth } from 'src/auth/context';

import type {
  AddHypothesisInput,
  AddHypothesisOutput,
  UpdateHypothesisInput,
  DeleteHypothesisInput,
  UpdateHypothesisOutput,
  DeleteHypothesisOutput,
  GenerateHypothesisInput,
} from '../../type';

export const businessModelHypothesisAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'POST': {
      const data = Object.fromEntries(await request.formData()) as GenerateHypothesisInput;

      const res = await generateBusinessModelHypothesis(data);

      return json(res);
    }
    case 'PUT': {
      const data = Object.fromEntries(await request.formData()) as AddHypothesisInput;

      const res = await addBusinessModelHypothesis(data);

      return json(res);
    }
    case 'PATCH': {
      const data = Object.fromEntries(await request.formData()) as UpdateHypothesisInput;

      const res = await updateBusinessModelHypothesis(data);

      return json(res);
    }
    case 'DELETE': {
      const data = Object.fromEntries(await request.formData()) as DeleteHypothesisInput;

      const res = await deleteBusinessModelHypothesis(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function deleteBusinessModelHypothesis(
  data: DeleteHypothesisInput
): Promise<ActionResponse<DeleteHypothesisOutput>> {
  try {
    const res = await client.models.Hypothesis.delete(data);

    return {
      userMessage: {
        message: '仮説（課題）を削除しました。',
        severity: 'success',
      },
      data: res.data,
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);
    return {
      userMessage: {
        message: '仮説（課題）の削除に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}

async function updateBusinessModelHypothesis(
  data: UpdateHypothesisInput
): Promise<ActionResponse<UpdateHypothesisOutput>> {
  try {
    const hypothesisId = data.id;
    const { hypothesisContent } = data;
    const kpiContents = data.kpiContents.split(',');
    const res = await client.models.Hypothesis.update({
      id: hypothesisId,
      hypothesisContent,
      kpiContents,
    });

    return {
      userMessage: {
        message: '仮説（課題）を編集しました。',
        severity: 'success',
      },
      data: res.data,
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message: '仮説（課題）の編集に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}

async function addBusinessModelHypothesis(
  data: AddHypothesisInput
): Promise<ActionResponse<AddHypothesisOutput>> {
  try {
    const res = await client.models.Hypothesis.create({
      ...data,
      createdAt: new Date().toISOString(),
    });

    return {
      userMessage: {
        message: '仮説（課題）を追加しました。',
        severity: 'success',
      },
      data: res.data,
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message: '仮説（課題）の追加に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}

async function generateBusinessModelHypothesis(
  data: GenerateHypothesisInput
): Promise<ActionResponse<null>> {
  try {
    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    const restOperation = await axios
      .post(
        `hypothesis`,
        {
          bm_id: data.businessModelId,
          workspace_id: data.workspaceId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.idToken.toString()}`,
          },
        }
      )
      .then((res) => res.data);

    if (restOperation.statusCode !== 200) {
      throw new Error('Failed to mutate businessModelHypothesisResolver');
    }

    return {
      userMessage: {
        message: '仮説（課題）を作成しました。',
        severity: 'success',
      },
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message: '仮説（課題）の作成に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}
